/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import guiaPNG from '../../assets/img/material-apoio/rede-credenciada-azul.png';
import apresentacaoPNG from '../../assets/img/material-apoio/capa-azul.png';
import telemedicinaPNG from '../../assets/img/material-apoio/guia-vendas-roxo.png';

import { Container, Content, Card } from './styles';

function MaterialApoio() {
  return (
    <Container>
      <Content>
        <Card>
          <figure>
            <img src={guiaPNG} alt="Guia" />
          </figure>

          <p>Guia Comercial - Fevereiro e Março de 2022</p>

          <a
            href="https://assets-hmg.s3.us-east-2.amazonaws.com/Dr_Hoje_Guia_Rede_Credenciada.pdf"
            target="_blank"
          >
            <button type="button">Acessar o guia</button>
          </a>
        </Card>

        <Card>
          <figure>
            <img src={apresentacaoPNG} alt="apresentacao" />
          </figure>

          <p>Material de Apoio e Treinamento</p>
          <a
            href="https://assets-hmg.s3.us-east-2.amazonaws.com/Dr_Hoje_Apresentacao_corretores.pdf"
            target="_blank"
          >
            <button type="button">Acessar o arquivo</button>
          </a>
        </Card>

        <Card>
          <figure>
            <img src={telemedicinaPNG} alt="apresentacao" />
          </figure>

          <p>Telemedicina para Empresas (flyer para o cliente)</p>
          <a
            href="https://assets-hmg.s3.us-east-2.amazonaws.com/Dr_Hoje_Guia_de_vendas_para_corretores.pdf"
            target="_blank"
          >
            <button type="button">Acessar o arquivo</button>
          </a>
        </Card>
      </Content>
    </Container>
  );
}

export default MaterialApoio;
